import { createSlice } from '@reduxjs/toolkit';
import { SEND_TEST_EMAIL, SUBMIT_EMAIL_CUSTOMIZATIONS } from './thunk';

interface CompanyCustomizationsState {
  submitEmailLoading: boolean;
  testEmailLoading: boolean;
}

const initialState: CompanyCustomizationsState = {
  submitEmailLoading: false,
  testEmailLoading: false,
};

const companyCustomizationsSlice = createSlice({
  name: 'companyCustomizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SUBMIT_EMAIL_CUSTOMIZATIONS.pending, (state) => {
        state.submitEmailLoading = true;
      })
      .addCase(SUBMIT_EMAIL_CUSTOMIZATIONS.fulfilled, (state) => {
        state.submitEmailLoading = false;
      })
      .addCase(SUBMIT_EMAIL_CUSTOMIZATIONS.rejected, (state) => {
        state.submitEmailLoading = false;
      })
      .addCase(SEND_TEST_EMAIL.pending, (state) => {
        state.testEmailLoading = true;
      })
      .addCase(SEND_TEST_EMAIL.fulfilled, (state) => {
        state.testEmailLoading = false;
      })
      .addCase(SEND_TEST_EMAIL.rejected, (state) => {
        state.testEmailLoading = false;
      });
  },
});

export default companyCustomizationsSlice.reducer;
